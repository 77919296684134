import { createAction } from '@reduxjs/toolkit'
import { ChainId } from '@pancakeswap/sdk'
import { Order } from '@gelatonetwork/limit-orders-lib'

export type TransactionType =
    | 'approve'
    | 'swap'
    | 'wrap'
    | 'add-liquidity'
    | 'remove-liquidity'
    | 'limit-order-submission'
    | 'limit-order-cancellation'
    | 'limit-order-approval'
    | 'stake-dao'
    | 'unstake-dao'
    | 'buy-lottery-ticket'
    | 'claim-rewards-success'
    | 'add-proposal'
    | 'approve-proposal'
    | 'cancel-approve-proposal'
    | 'exec-approve-proposal'

export interface SerializableTransactionReceipt {
    to: string
    from: string
    contractAddress: string
    transactionIndex: number
    blockHash: string
    transactionHash: string
    blockNumber: number
    status?: number
}

export const addTransaction = createAction<{
    chainId: ChainId
    hash: string
    from: string
    approval?: { tokenAddress: string; spender: string }
    claim?: { recipient: string }
    summary?: string
    type?: TransactionType
    order?: Order
}>('transactions/addTransaction')
export const clearAllTransactions = createAction<{ chainId: ChainId }>('transactions/clearAllTransactions')
export const finalizeTransaction = createAction<{
    chainId: ChainId
    hash: string
    receipt: SerializableTransactionReceipt
}>('transactions/finalizeTransaction')
export const checkedTransaction = createAction<{
    chainId: ChainId
    hash: string
    blockNumber: number
}>('transactions/checkedTransaction')
