import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  TrophyFillIcon,
  TrophyIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

// WEIYA: 顶部导航菜单配置  Menu This is the configuration for the MENU.

const config: (t: ContextApi['t'], chainId?: any, langCode?: string) => ConfigMenuItemsType[] = (
  t,
  chainId,
  langCode,
) => {
  // console.info('===lang===menuItems', chainId, langCode, t('Trade'))
  if (langCode !== 'EN') {
    setTimeout(() => {
      return [
        {
          label: t('Trade'),
          icon: SwapIcon,
          fillIcon: SwapFillIcon,
          href: '/swap',
          showItemsOnMobile: false,
          items: [],
        },

        {
          label: t('Liquidity'),
          icon: SwapIcon,
          fillIcon: SwapFillIcon,
          href: '/liquidity',
          showItemsOnMobile: false,
          items: [],
        },
        {
          label: t('Stake DAO'),
          href: '/stake',
          icon: EarnIcon,
          fillIcon: EarnFillIcon,
          showItemsOnMobile: false,
          items: [],
        },
        {
          label: t('Multisig'),
          href: '/multisig',
          icon: EarnIcon,
          fillIcon: EarnFillIcon,
          showItemsOnMobile: false,
          items: [],
        },
        {
          label: t('Lottery'),
          href: '/lottery',
          icon: TrophyIcon,
          fillIcon: TrophyFillIcon,
          showItemsOnMobile: false,
          items: [],
        },
      ]
    }, 2000)
  }
  return [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [],
    },

    {
      label: t('Liquidity'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/liquidity',
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Stake DAO'),
      href: '/stake',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Multisig'),
      href: '/multisig',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('Lottery'),
      href: '/lottery',
      icon: TrophyIcon,
      fillIcon: TrophyFillIcon,
      showItemsOnMobile: false,
      items: [],
    },
  ]
}

export default config
