import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import { Address, IllegalAddress } from 'config/constants/types'
import { VaultKey } from 'state/types'
import daoConfig from 'config/dao/index'

export const getAddress = (address: Address): string => {
  const chainId = process.env.NEXT_PUBLIC_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getIllegalAddress = (address: IllegalAddress): string[] => {
  const chainId = process.env.NEXT_PUBLIC_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMasterChefV1Address = () => {
  return getAddress(addresses.masterChefV1)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeBunniesAddress = () => {
  return getAddress(addresses.pancakeBunnies)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddressEaster = () => {
  return getAddress(addresses.tradingCompetitionEaster)
}
export const getTradingCompetitionAddressFanToken = () => {
  return getAddress(addresses.tradingCompetitionFanToken)
}

export const getTradingCompetitionAddressMobox = () => {
  return getAddress(addresses.tradingCompetitionMobox)
}

export const getTradingCompetitionAddressMoD = () => {
  return getAddress(addresses.tradingCompetitionMoD)
}

export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}

export const getVaultPoolAddress = (vaultKey: VaultKey) => {
  if (!vaultKey) {
    return null
  }
  return getAddress(addresses[vaultKey])
}

export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}

export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}
export const getBunnySpecialXmasAddress = () => {
  return getAddress(addresses.bunnySpecialXmas)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getAnniversaryAchievement = () => {
  return getAddress(addresses.AnniversaryAchievement)
}
export const getGalaxyNFTClaimingAddress = () => {
  return getAddress(addresses.galaxyNftClaiming)
}
export const getNftMarketAddress = () => {
  return getAddress(addresses.nftMarket)
}
export const getNftSaleAddress = () => {
  return getAddress(addresses.nftSale)
}
export const getPancakeSquadAddress = () => {
  return getAddress(addresses.pancakeSquad)
}

// WEIYA: 获取 stakeDaoAddress
export const getDaoStakeAddress = () => {
  return getAddress(daoConfig.DAOSwapToEarn)
}

// WEIYA: 获取 DaoTokenAddress
export const getDaoTokenAddress = () => {
  return getAddress(daoConfig.DAOToken)
}

// WEIYA: 获取 getUsdtTokenAddress
export const getUsdtTokenAddress = () => {
  return getAddress(daoConfig.USDT)
}

// WEIYA: 获取 DAOLotteryContractAddress
export const getDAOLotteryContractAddress = () => {
  return getAddress(daoConfig.DAOLotteryContract)
}

// WEIYA: 获取 getDAORouterContractAddress
export const getDAORouterContractAddress = () => {
  return getAddress(daoConfig.DAORouter)
}

// WEIYA: 获取 getDAORouterContractAddress
export const getUsdtContractAddress = () => {
  return getAddress(daoConfig.DAORouter)
}

// WEIYA: 代币 1 dao
export const getDAOLotteryPatchContractAddress = () => {
  return getAddress(daoConfig.DAOLotteryPatchContract)
}

// WEIYA:  代币 2
export const getDAOLotteryPatch2ContractAddress = () => {
  return getAddress(daoConfig.DAOLotteryPatch2Contract)
}

// WEIYA:  代币 3
export const getDAOLotteryPatch3ContractAddress = () => {
  return getAddress(daoConfig.DAOLotteryPatch3Contract)
}

// WEIYA:  代币 3 代币地址 ETHD
export const getDAOLotteryRewardsETHDTokenAddress = () => {
  return getAddress(daoConfig.DAOLotteryRewardsETHDContract)
}

// WEIYA: 获取 getDAOStakeContractAddress
export const getDAOStakeContractAddress = () => {
  return getAddress(daoConfig.DAOStakeContract)
}

// WEIYA: 获取 getDAOMultisignContractAddress
export const getDAOMultisignContractAddress = () => {
  return getAddress(daoConfig.DAOMultisignContract)
}
// WEIYA: 获取 getDAOStakeTokenAddress
export const getDAOStakeTokenAddressNew = () => {
  return getAddress(daoConfig.DAOTokenNew)
}

// WEIYA: 获取 DaoTokenAddressNew
export const getDaoTokenAddressNew = () => {
  return getAddress(daoConfig.DAOTokenNew)
}

// WEIYA: 获取 stakeDaoAddress
export const getDaoStakeAddressNew = () => {
  return getAddress(daoConfig.DAOSwapToEarnNew)
}

// Get the address in an impassable environment
export const getIllegalWalletAddress = () => {
  return getIllegalAddress(daoConfig.IllegalWalletAddress)
}
