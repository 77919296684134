import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isAddress } from 'utils'
import { setInviter, setLotteryInviter } from '../../state/swap/actions'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string')

const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/

function validatedInviter(inviter: any): string | null | undefined {
  if (typeof inviter !== 'string') return undefined
  const address = isAddress(inviter)

  if (address) return address

  if (ADDRESS_REGEX.test(inviter)) return inviter

  return undefined
}

const RouterInviterUpdater: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    const { inviter } = queryString.parse(window.location.search)
    const { lotteryRef } = queryString.parse(window.location.search)

    // console.error('=== RouterInviterUpdater ===', inviter, query, window.location, parsedInviter)
    console.warn('parsedInviter', 'RouterInviterUpdater', inviter)
    console.warn('lotteryRef', 'RouterInviterUpdater', lotteryRef)
    const validatedAddress = validatedInviter(inviter)

    window.sessionStorage.setItem('session_inviter', validatedAddress)
    window.sessionStorage.setItem('session_lottery_inviter', lotteryRef ?? '')
    dispatch(setInviter({ inviter: validatedAddress }))
    dispatch(setLotteryInviter({ lotteryRef: lotteryRef ?? '' }))
  }, [])
  return null
}

export default RouterInviterUpdater
