import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["MAINNET"] = 56] = "MAINNET";
    ChainId[ChainId["TESTNET"] = 97] = "TESTNET";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
// WEIYA: FACTORY_ADDRESS FACTORY 地址
export var FACTORY_ADDRESS = "0x3265CdE5843e02E35c45C4b9352d3C1c4a542Ff4";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.MAINNET, FACTORY_ADDRESS), // [ChainId.TESTNET]: '0x6725f303b657a9451d8ba641348b6761a6cc7a17',
// WEIYA: FACTORY_ADDRESS update 测试网地址
_define_property(_obj, ChainId.TESTNET, "0x017c51DA2Ac166c85Fbdc8f4C2B0567D46a7ED9A"), _obj);
// WEIYA: HASH CONFIG FACTORY_ADDRESS 缺少密钥hash 注意更新密钥hash 跟 合约一定要对应上
export var INIT_CODE_HASH = "0x1f2201003cf8ed50cef5628438a13380e414d6f6db38d0f016e00d9fbbd419ff";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.MAINNET, INIT_CODE_HASH), _define_property(_obj1, ChainId.TESTNET, "0x1f2201003cf8ed50cef5628438a13380e414d6f6db38d0f016e00d9fbbd419ff"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var FEES_NUMERATOR = JSBI.BigInt(9975);
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
