import sample from 'lodash/sample'

const isDev = process.env.NODE_ENV !== 'production'
if (
  process.env.NODE_ENV !== 'production' &&
  (!process.env.NEXT_PUBLIC_NODE_1 || !process.env.NEXT_PUBLIC_NODE_2 || !process.env.NEXT_PUBLIC_NODE_3)
) {
  throw Error('One base RPC URL is undefined')
}

// WEIYA:  随机获取 节点
// Array of available nodes to connect to
export const nodes = [process.env.NEXT_PUBLIC_NODE_1, process.env.NEXT_PUBLIC_NODE_2, process.env.NEXT_PUBLIC_NODE_3]

const getNodeUrl = () => {
  // FIXME:  don't use pancake, use a random bsc node
  // Use custom node if available (both for development and production)
  // However on the testnet it wouldn't work, so if on testnet - comment out the NEXT_PUBLIC_NODE_PRODUCTION from env file
  // if (process.env.NEXT_PUBLIC_NODE_PRODUCTION) {
  //   return process.env.NEXT_PUBLIC_NODE_PRODUCTION
  // }

  // if (isDev) {
  //   return process.env.NEXT_PUBLIC_NODE_1
  // }

  const nodeRPC = sample(nodes)

  console.log('%c === Log Log Random URL ===', 'color: blue', nodeRPC)
  return nodeRPC
}

export default getNodeUrl
